import { SvgIcon } from "@mui/material";

export const Cross = () => {
  return (
    <SvgIcon width="15" height="15" viewBox="0 0 15 15">
      <path
        d="M0.339709 12.9308C-0.110176 13.3806 -0.119357 14.1794 0.348891 14.6477C0.82632 15.1159 1.6251 15.1067 2.0658 14.666L7.50115 9.23068L12.9273 14.6568C13.3864 15.1159 14.176 15.1159 14.6442 14.6477C15.1125 14.1702 15.1125 13.3898 14.6534 12.9308L9.22724 7.50459L14.6534 2.06925C15.1125 1.61018 15.1217 0.820589 14.6442 0.352342C14.176 -0.115906 13.3864 -0.115906 12.9273 0.34316L7.50115 5.76932L2.0658 0.34316C1.6251 -0.106724 0.817138 -0.125087 0.348891 0.352342C-0.119357 0.820589 -0.110176 1.62855 0.339709 2.06925L5.76588 7.50459L0.339709 12.9308Z"
        fill="#231F20"
      />
    </SvgIcon>
  );
};
