import { makeAutoObservable, runInAction } from "mobx";
import request from "api";
import { AxiosResponse } from "axios";
import { successNotification } from "utils/notifications";

type PolicyType = {
  version: string;
  text: string;
  id: number;
  created_at: string;
};

class PolicyStore {
  currentPrivacyPolicy: PolicyType | null = null;
  isPolicyLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getPolicy = (successCallback?: () => void) => {
    const id = 1;
    this.setIsPolicyLoading(true);
    request({ url: `/api/admin/policy/${id}` })
      .then(({ data }: AxiosResponse<PolicyType>) => {
        runInAction(() => {
          this.currentPrivacyPolicy = data;
        });
        successCallback && successCallback();
      })
      .finally(() => {
        this.setIsPolicyLoading(false);
      });
  };

  updatePolicy = (
    payload: Omit<PolicyType, "id" | "created_at">,
    successCallback?: () => void,
  ) => {
    const id = 1;
    request({
      url: `/api/admin/policy/${id}`,
      method: "put",
      data: payload,
    }).then(() => {
      successCallback && successCallback();
      successNotification("Privacy Policy successfully updated!");
    });
  };

  setIsPolicyLoading = (isLoading: boolean) => {
    this.isPolicyLoading = isLoading;
  };

  clear = () => {};
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PolicyStore();
