import { AttentionShield } from "assets/IconsComponents/AttentionShield";
import { OKShield } from "assets/IconsComponents/OKShield";
import { toast } from "react-toastify";

export const successNotification = (text: string) => {
  toast.success(text, {
    icon: OKShield,
  });
};
export const errorNotification = (text: string) => {
  toast.error(text, {
    icon: AttentionShield,
  });
};
