import { Box } from "@mui/material";
import { CheckboxSelected } from "assets/IconsComponents/CheckboxSelected";
import { CheckboxUnselected } from "assets/IconsComponents/CheckboxUnselected";

const CustomCheckbox: React.FC<{
  value?: boolean;
  id?: string;
  onClick?: () => void;
}> = ({ value, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        height: 19,
        width: 19,
        "& svg": {
          height: 19,
          width: 19,
          fill: "transparent",
        },
      }}
    >
      {value ? <CheckboxSelected /> : <CheckboxUnselected />}
    </Box>
  );
};

export default CustomCheckbox;
