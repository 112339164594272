import { createTheme } from "@mui/material/styles";
import "../CSS/index.css";

const theme = createTheme({
  palette: {
    common: {
      black: "#231F20",
      white: "#fff",
    },
    primary: {
      main: "#00A6B3",
      light: "#02B9C7",
    },
    secondary: {
      main: "#FCAB38",
      light: "#FFFBF7",
      dark: "#9B9B9B",
    },
    error: {
      main: "#E31616",
      light: "rgb(227, 22, 22, 0.07)",
    },
    text: {
      primary: "#231F20",
    },
    action: {
      disabled: "#9B9B9B",
    },
  },
  typography: {
    fontFamily: ["figtree", "sans-serif"].join(","),
    fontSize: 10,
    htmlFontSize: 10,
  },
  shape: {
    borderRadius: 1,
  },
});

export default theme;
