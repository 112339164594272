import MainLayout from "components/MainLayout";

import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import User from "singletons/User";
import { PrivateRoutes } from "./PrivateRoutes";
import { routes } from "utils/constants/routes";

const ProjectRoutes: React.FC = () => {
  useEffect(() => {
    User.getUserInfo();
  }, []);

  return (
    <MainLayout>
      <Routes>
        {routes.map(({ path, Component }) => (
          <Route element={<PrivateRoutes />}>
            <Route key={path} path={path} element={<Component />} />
          </Route>
        ))}
      </Routes>
    </MainLayout>
  );
};

export default ProjectRoutes;
