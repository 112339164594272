import request from "api";
import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { errorNotification } from "utils/notifications";

class CustomersStore {
  searchValue: string = "";
  customers: Array<any> = [];
  isCustomersLoading = true;
  currentPage: number = 1;
  hasMore: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getCustomers = (
    searchValue: string,
    isActive: boolean | null = null,
    page: number | null = null,
    withoutLoader: boolean = false,
  ) => {
    !withoutLoader && this.setCustomersLoading(true);

    request({
      url: `/api/admin/customers`,
      params: {
        is_active: isActive,
        q: searchValue,
        page: page,
      },
      headers: {
        "Cache-Control": "no-store",
      },
    })
      .then(
        ({
          data: { page: responsePage, items, total },
        }: AxiosResponse<{
          items: Array<any>;
          page: number;
          total: number;
        }>) => {
          runInAction(() => {
            this.customers = page ? toJS(this.customers).concat(items) : items;
            this.currentPage = responsePage;
            this.hasMore = this.customers.length < total;
          });
        },
      )
      .catch(e => {
        runInAction(() => {
          this.customers = [];
          this.currentPage = 1;
          this.hasMore = false;
        });

        if (e.response?.status !== 401) {
          errorNotification("An error occured while recieving customers list");
        }
      })
      .finally(() => {
        !withoutLoader && this.setCustomersLoading(false);
      });
  };

  getMoreCustomers = (searchValue: string, isActive: boolean | null = null) => {
    this.getCustomers(searchValue, isActive, this.currentPage + 1, true);
  };

  setCustomersLoading = (isLoading: boolean) => {
    this.isCustomersLoading = isLoading;
  };

  setSearchValue = (searchValue: string) => {
    this.searchValue = searchValue;
  };

  clear = () => {
    this.customers = [];
    this.currentPage = 1;
    this.hasMore = false;
    this.searchValue = "";
    this.isCustomersLoading = false;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomersStore();
