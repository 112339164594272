import { Box, Typography, useTheme } from "@mui/material";
import { Email } from "assets/IconsComponents/Email";
import { PasswordLock } from "assets/IconsComponents/PasswordLock";
import Button from "components/Button";
import { FormInput } from "components/Input";
import { PasswordInput } from "components/Input/PasswordInput";
import { observer } from "mobx-react-lite";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Login from "singletons/Login";
import { emailValidationRules } from "utils/constants";

const LoginPage: React.FC = () => {
  const theme = useTheme();
  const { control, handleSubmit } = useForm({
    defaultValues: { username: "", password: "" },
  });

  const onSubmit: SubmitHandler<{
    username: string;
    password: string;
  }> = values => {
    Login.getAccessToken(values);
  };
  return (
    <Box
      sx={{
        height: "100vh",
        background: theme => theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            background: theme => theme.palette.common.white,
            width: 440,
            height: 480,
            padding: "56px",
            borderRadius: 40,
            justifyContent: "center",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "center",
            display: "flex",
            gap: "20px",
          }}
        >
          <Typography
            color={"text.primary"}
            fontSize={"3rem"}
            fontWeight={500}
            width={328}
            textAlign={"center"}
            mb="20px"
          >
            Welcome to "The Voy" admin panel!
          </Typography>
          <FormInput
            control={control}
            name={"username"}
            rules={emailValidationRules}
            placeHolder={"Email"}
            startAdornment={<Email />}
          />
          <PasswordInput
            control={control}
            name={"password"}
            rules={{ required: "Required" }}
            placeHolder={"Password"}
            startAdornment={<PasswordLock />}
          />
          <Link
            to={"/forgot-password"}
            style={{
              color: theme.palette.secondary.main,
              fontSize: "1.2rem",
              fontFamily: "figtree",
              textDecoration: "none",
              alignSelf: "end",
            }}
          >
            Forgot password
          </Link>
          <Button
            type="submit"
            title={"Login"}
            isLoading={Login.isLoginPending}
          />
        </Box>
      </form>
    </Box>
  );
};

export default observer(LoginPage);
