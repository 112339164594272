import React, { useEffect, useState } from "react";
import CustomSwitch from "components/Switch";
import { PersonIcon } from "assets/IconsComponents/PersonIcon";
import Loader from "components/Loader";
import { Box } from "@mui/material";
import CustomerStore from "singletons/CustomerStore";
import { observer } from "mobx-react-lite";
const CustomerProfile: React.FC<{ customerID: string }> = ({ customerID }) => {
  const [isUserActive, setUserActive] = useState<null | boolean>(null);

  const {
    customer,
    isCustomerLoading,
    isEditCustomerPending,
    changeCustomerActiveState,
  } = CustomerStore;

  useEffect(() => {
    CustomerStore.getCustomerDetails(customerID);
  }, [customerID]);

  return isCustomerLoading || isEditCustomerPending ? (
    <Loader />
  ) : (
    <>
      {customer ? (
        <>
          <Box
            sx={{
              height: 100,
              width: 100,
              borderRadius: "35px",
              backgroundColor: ({ palette }) => palette.primary.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
              "& svg": {
                height: 50,
                width: 50,
                borderRadius: "35px",
                backgroundColor: ({ palette }) => palette.primary.main,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "5px",
              },
              "& img": {
                height: "inherit",
                width: "inherit",
                borderRadius: "35px",
                backgroundColor: ({ palette }) => palette.primary.main,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            {customer.avatar ? (
              <img src={customer.avatar.file_url} alt={"Avatar"} />
            ) : customer.first_name && customer.last_name ? (
              <Box
                sx={{
                  fontSize: "3rem",
                  color: ({ palette }) => palette.common.white,
                }}
              >
                {customer.first_name.charAt(0)?.toLocaleUpperCase() +
                  customer.last_name.charAt(0)?.toLocaleUpperCase()}
              </Box>
            ) : (
              <PersonIcon />
            )}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "5px",
              fontSize: "1.4rem",
              fontWeight: 300,
              "& :nth-child(-2n + 7)": {
                fontWeight: 500,
              },
            }}
          >
            <Box>First name:</Box>
            <Box>{customer.first_name || "No information added"}</Box>
            <Box>Last name:</Box>
            <Box>{customer.last_name || "No information added"}</Box>
            <Box>Email:</Box>
            <Box>{customer.email}</Box>
            <Box>Status:</Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <CustomSwitch
                value={isUserActive ?? customer.is_active}
                disabled={isUserActive === null}
                onClick={event => {
                  setUserActive(event.target.checked);
                }}
              />
              <Box
                sx={{
                  fontSize: "1.6rem",
                  fontWeight: 600,
                  color: ({ palette }) => palette.primary.main,
                  display: "flex",
                  gap: 1,
                  "&>*": { cursor: "pointer" },
                }}
              >
                {isUserActive === null ? (
                  <Box
                    onClick={() => {
                      setUserActive(customer.is_active);
                    }}
                  >
                    Edit
                  </Box>
                ) : (
                  <>
                    <Box
                      onClick={() => {
                        setUserActive(null);
                      }}
                      color={theme => theme.palette.secondary.dark}
                    >
                      Cancel
                    </Box>
                    <Box
                      onClick={() => {
                        changeCustomerActiveState(
                          customerID,
                          isUserActive,
                          () => {
                            setUserActive(null);
                          },
                        );
                      }}
                    >
                      Submit
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box>OSHIBKA</Box>
      )}
    </>
  );
};

export default observer(CustomerProfile);
