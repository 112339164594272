import { SvgIcon } from "@mui/material";

export const CheckboxUnselected = () => {
  return (
    <SvgIcon viewBox="0 0 19 19">
      <rect
        x="0.5"
        y="0.5"
        width="18"
        height="18"
        rx="0.5"
        fill="white"
        stroke="#9B9B9B"
      />
    </SvgIcon>
  );
};
