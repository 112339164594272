import request from "api";
import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { errorNotification } from "utils/notifications";

type Customer = {
  id: number;
  email: string;
  first_name?: string;
  last_name?: string;
  avatar: null | { id: number; file_url: string };
  is_active: boolean;
  profile: {
    id: number;
    is_private: boolean;
    bio: string;
  };
  terms_id: number;
  policy_id: number;
  user_following_count: number;
  boat_following_count: number;
  total_following_count: number;
  total_followers_count: number;
};

type Boat = {
  brand: { created_at: string; id: number; title: string };
  name: string;
  photo?: { id: number; file_url: string };
};
type Trip = {
  id: number;
  media?: {
    id: number;
    file_url: string;
    created_at: string;
  };
  name: string;
  start_time: string;
  end_time?: string;
  membership: boolean;
};

class CustomerStore {
  customer: Customer | null = null;
  isCustomerLoading = true;
  isEditCustomerPending: boolean = false;

  boats: Array<Boat> = [];
  isCustomerBoatsLoading = true;
  currentBoatPage: number = 1;
  hasMoreBoats: boolean = false;

  trips: Array<Trip> = [];
  isCustomerTripsLoading = true;
  currentTripPage: number = 1;
  hasMoreTrips: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getCustomerDetails = (id: string) => {
    this.setCustomerLoading(true);

    request({
      url: `/api/admin/customers/${id}`,
    })
      .then(({ data }: AxiosResponse<Customer>) => {
        runInAction(() => {
          this.customer = data;
        });
      })
      .catch(e => {
        runInAction(() => {
          this.customer = null;
        });

        if (e.response?.status !== 401) {
          errorNotification(
            "An error occured while recieving customer profile",
          );
        }
      })
      .finally(() => {
        this.setCustomerLoading(false);
      });
  };

  getCustomerBoats = (id: string, page: number | null = null) => {
    !page && this.setCustomerBoatsLoading(true);

    request({
      url: `/api/admin/customers/${id}/boats`,
      params: { page },
      headers: {
        "Cache-Control": "no-store",
      },
    })
      .then(
        ({
          data: { items, total, page: responsePage },
        }: AxiosResponse<{
          items: Array<Boat>;
          page: number;
          total: number;
        }>) => {
          runInAction(() => {
            this.boats = toJS(this.boats).concat(items);
            this.currentBoatPage = responsePage;
            this.hasMoreBoats = this.boats.length < total;
          });
        },
      )
      .catch(e => {
        runInAction(() => {
          this.boats = [];
          this.currentBoatPage = 1;
          this.hasMoreBoats = false;
        });

        if (e.response?.status !== 401) {
          errorNotification("An error occured while recieving boats");
        }
      })
      .finally(() => {
        !page && this.setCustomerBoatsLoading(false);
      });
  };

  getMoreCustomerBoats = (customerID: string) => {
    this.getCustomerBoats(customerID, this.currentBoatPage + 1);
  };

  getCustomerTrips = (id: string, page: number | null = null) => {
    !page && this.setCustomerTripsLoading(true);

    request({
      url: `/api/admin/customers/${id}/trips`,
      params: { page, is_membership: true },
      headers: {
        "Cache-Control": "no-store",
      },
    })
      .then(
        ({
          data: { items, page, total },
        }: AxiosResponse<{
          items: Array<Trip>;
          page: number;
          total: number;
        }>) => {
          runInAction(() => {
            this.trips = toJS(this.trips).concat(items);
            this.currentTripPage = page;
            this.hasMoreTrips = this.trips.length < total;
          });
        },
      )
      .catch(e => {
        runInAction(() => {
          this.trips = [];
        });

        if (e.response?.status !== 401) {
          errorNotification("An error occured while recieving trips");
        }
      })
      .finally(() => {
        this.setCustomerTripsLoading(false);
      });
  };

  getMoreCustomerTrips = (customerID: string) => {
    this.getCustomerTrips(customerID, this.currentTripPage + 1);
  };

  changeCustomerActiveState = (
    id: string,
    state: boolean,
    successCallback?: () => void,
  ) => {
    this.setIsCustomerPending(true);

    if (state) {
      this.setCustomerActive(id, successCallback);
    } else {
      this.setCustomerInactive(id, successCallback);
    }
  };

  setCustomerInactive = (id: string, successCallback?: () => void) => {
    request({ url: `/api/admin/customers/${id}`, method: "delete" })
      .then(() => {
        this.getCustomerDetails(id);
        successCallback && successCallback();
      })
      .finally(() => {
        this.setIsCustomerPending(false);
      });
  };

  setCustomerActive = (id: string, successCallback?: () => void) => {
    request({ url: `/api/admin/customers/${id}`, method: "post" })
      .then(() => {
        this.getCustomerDetails(id);
        successCallback && successCallback();
      })
      .finally(() => {
        this.setIsCustomerPending(false);
      });
  };

  setCustomerLoading = (isLoading: boolean) => {
    this.isCustomerLoading = isLoading;
  };
  setCustomerBoatsLoading = (isLoading: boolean) => {
    this.isCustomerBoatsLoading = isLoading;
  };
  setCustomerTripsLoading = (isLoading: boolean) => {
    this.isCustomerTripsLoading = isLoading;
  };
  setIsCustomerPending = (isPending: boolean) => {
    this.isEditCustomerPending = isPending;
  };

  clear = () => {
    this.customer = null;
    this.isCustomerLoading = false;
    this.isEditCustomerPending = false;

    this.boats = [];
    this.isCustomerBoatsLoading = true;
    this.currentBoatPage = 1;
    this.hasMoreBoats = false;

    this.trips = [];
    this.isCustomerTripsLoading = true;
    this.currentTripPage = 1;
    this.hasMoreTrips = false;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerStore();
