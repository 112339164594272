import { Box, Typography } from "@mui/material";
import { PasswordLock } from "assets/IconsComponents/PasswordLock";
import Button from "components/Button";
import { PasswordInput } from "components/Input/PasswordInput";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useSearchParams } from "react-router-dom";
import Login from "singletons/Login";
import { passwordValidationRules } from "utils/constants";

const ForgotPasswordConfirm: React.FC = () => {
  const [isSubmited, setSubmited] = useState(false);
  const [searchParams] = useSearchParams();
  const { control, handleSubmit } = useForm({
    defaultValues: { password: "", password_confirm: "" },
  });

  const token = searchParams.get("token");

  if (!token) {
    Login.logout();
  }

  const onSubmit: SubmitHandler<{
    password: string;
    password_confirm: string;
  }> = values => {
    if (token) {
      Login.confirmPasswordReset({ token, ...values }, () => {
        setSubmited(true);
      });
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        background: theme => theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            background: theme => theme.palette.common.white,
            width: 440,
            height: 440,
            padding: "56px",
            borderRadius: 40,
            justifyContent: "center",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "center",
            display: "flex",
            gap: "20px",
          }}
        >
          {isSubmited ? (
            <Navigate to={"/login"} />
          ) : (
            <>
              <Typography
                color={"text.primary"}
                fontSize={"3rem"}
                fontWeight={500}
                width={328}
                textAlign={"center"}
              >
                Reset password
              </Typography>
              <Typography
                color={"text.primary"}
                fontSize={"2rem"}
                fontWeight={400}
                width={328}
                textAlign={"center"}
                mb={"10px"}
              >
                Please enter new Password
              </Typography>
              <PasswordInput
                control={control}
                name={"password"}
                rules={passwordValidationRules}
                placeHolder={"Password"}
                startAdornment={<PasswordLock />}
              />

              <PasswordInput
                control={control}
                name={"password_confirm"}
                rules={passwordValidationRules}
                placeHolder={"Confirm password"}
                startAdornment={<PasswordLock />}
              />

              <Button
                type="submit"
                title={"Submit"}
                isLoading={Login.isForgotPasswordConfirmPending}
              />
            </>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default observer(ForgotPasswordConfirm);
