import { Switch } from "@mui/material";

const CustomSwitch: React.FC<{
  value: boolean;
  disabled?: boolean;
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ value, disabled, onClick }) => {
  return (
    <>
      <Switch
        checked={value}
        //@ts-ignore
        onClick={onClick}
        disabled={disabled}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        size="small"
        sx={theme => ({
          "&": {
            width: 42,
            height: 24,
            padding: "0!important",
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: "3px",
              transitionDuration: "300ms",
              "&.Mui-checked": {
                color: theme.palette.common.white,
                transform: "translateX(18px)",
                "& + .MuiSwitch-track": {
                  backgroundColor: theme.palette.secondary.main,

                  opacity: 1,
                  border: 0,
                },
              },
            },

            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              boxShadow: "none",
              width: 18,
              height: 18,
            },
            "& .MuiSwitch-track": {
              borderRadius: 11,
              width: 42,
              height: 24,
              opacity: 1,
              backgroundColor: theme.palette.secondary.dark,
            },
            "&&& .Mui-disabled": {
              color: ({ palette }) => palette.common.white,
            },
          },
        })}
      />
    </>
  );
};

export default CustomSwitch;
