import React, { useState } from "react";
import { DeepPartial, FieldValues, UseFormWatch } from "react-hook-form";

export const useFormValues: <T extends FieldValues>(
  watch: UseFormWatch<T>,
  defaultValues: DeepPartial<T>,
) => DeepPartial<T> = (watch, defaultValues) => {
  //   const values = useRef(defaultValues);
  const [values, setValues] = useState(defaultValues);

  React.useEffect(() => {
    const subscription = watch(value => {
      setValues(value);
    }, defaultValues);

    return () => subscription.unsubscribe();
  }, [defaultValues, watch]);

  return values;
};
