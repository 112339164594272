import { SvgIcon } from "@mui/material";

export const PasswordEyeOpened = () => {
  return (
    <SvgIcon width="15" height="12" viewBox="0 0 15 12">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.73828 3.94796C6.38968 3.94796 5.29642 4.90735 5.29642 6.09082C5.29642 7.27429 6.38968 8.23368 7.73828 8.23368C9.08688 8.23368 10.1801 7.27429 10.1801 6.09082C10.1801 4.90735 9.08688 3.94796 7.73828 3.94796ZM6.27317 6.09082C6.27317 5.38074 6.92912 4.80511 7.73828 4.80511C8.54744 4.80511 9.2034 5.38074 9.2034 6.09082C9.2034 6.8009 8.54744 7.37653 7.73828 7.37653C6.92912 7.37653 6.27317 6.8009 6.27317 6.09082Z"
        fill="#FCAB38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.73828 1.09082C4.79885 1.09082 2.81894 2.63608 1.66981 3.94617L1.64909 3.96979C1.38921 4.26598 1.14986 4.53878 0.987473 4.86133C0.813585 5.20675 0.738281 5.58321 0.738281 6.09082C0.738281 6.59843 0.813585 6.97489 0.987473 7.32031C1.14986 7.64287 1.38921 7.91566 1.64909 8.21185L1.66981 8.23547C2.81894 9.54557 4.79885 11.0908 7.73828 11.0908C10.6777 11.0908 12.6576 9.54557 13.8067 8.23547L13.8275 8.21187C14.0873 7.91567 14.3267 7.64287 14.4891 7.32031C14.663 6.97489 14.7383 6.59843 14.7383 6.09082C14.7383 5.58321 14.663 5.20675 14.4891 4.86133C14.3267 4.53877 14.0873 4.26597 13.8275 3.96978L13.8067 3.94617C12.6576 2.63608 10.6777 1.09082 7.73828 1.09082ZM2.44382 4.46899C3.50484 3.25935 5.23154 1.94796 7.73828 1.94796C10.245 1.94796 11.9717 3.25935 13.0327 4.46899C13.3183 4.79459 13.4856 4.98914 13.5956 5.20771C13.6985 5.41197 13.7615 5.66164 13.7615 6.09082C13.7615 6.52 13.6985 6.76967 13.5956 6.97394C13.4856 7.1925 13.3183 7.38706 13.0327 7.71265C11.9717 8.9223 10.245 10.2337 7.73828 10.2337C5.23154 10.2337 3.50484 8.9223 2.44382 7.71265C2.15823 7.38706 1.99095 7.1925 1.88092 6.97394C1.77808 6.76967 1.71503 6.52 1.71503 6.09082C1.71503 5.66164 1.77808 5.41197 1.88092 5.20771C1.99095 4.98914 2.15823 4.79459 2.44382 4.46899Z"
        fill="#FCAB38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.73828 3.94796C6.38968 3.94796 5.29642 4.90735 5.29642 6.09082C5.29642 7.27429 6.38968 8.23368 7.73828 8.23368C9.08688 8.23368 10.1801 7.27429 10.1801 6.09082C10.1801 4.90735 9.08688 3.94796 7.73828 3.94796ZM6.27317 6.09082C6.27317 5.38074 6.92912 4.80511 7.73828 4.80511C8.54744 4.80511 9.2034 5.38074 9.2034 6.09082C9.2034 6.8009 8.54744 7.37653 7.73828 7.37653C6.92912 7.37653 6.27317 6.8009 6.27317 6.09082Z"
        stroke="#FCAB38"
        stroke-width="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.73828 1.09082C4.79885 1.09082 2.81894 2.63608 1.66981 3.94617L1.64909 3.96979C1.38921 4.26598 1.14986 4.53878 0.987473 4.86133C0.813585 5.20675 0.738281 5.58321 0.738281 6.09082C0.738281 6.59843 0.813585 6.97489 0.987473 7.32031C1.14986 7.64287 1.38921 7.91566 1.64909 8.21185L1.66981 8.23547C2.81894 9.54557 4.79885 11.0908 7.73828 11.0908C10.6777 11.0908 12.6576 9.54557 13.8067 8.23547L13.8275 8.21187C14.0873 7.91567 14.3267 7.64287 14.4891 7.32031C14.663 6.97489 14.7383 6.59843 14.7383 6.09082C14.7383 5.58321 14.663 5.20675 14.4891 4.86133C14.3267 4.53877 14.0873 4.26597 13.8275 3.96978L13.8067 3.94617C12.6576 2.63608 10.6777 1.09082 7.73828 1.09082ZM2.44382 4.46899C3.50484 3.25935 5.23154 1.94796 7.73828 1.94796C10.245 1.94796 11.9717 3.25935 13.0327 4.46899C13.3183 4.79459 13.4856 4.98914 13.5956 5.20771C13.6985 5.41197 13.7615 5.66164 13.7615 6.09082C13.7615 6.52 13.6985 6.76967 13.5956 6.97394C13.4856 7.1925 13.3183 7.38706 13.0327 7.71265C11.9717 8.9223 10.245 10.2337 7.73828 10.2337C5.23154 10.2337 3.50484 8.9223 2.44382 7.71265C2.15823 7.38706 1.99095 7.1925 1.88092 6.97394C1.77808 6.76967 1.71503 6.52 1.71503 6.09082C1.71503 5.66164 1.77808 5.41197 1.88092 5.20771C1.99095 4.98914 2.15823 4.79459 2.44382 4.46899Z"
        stroke="#FCAB38"
        stroke-width="0.4"
      />
    </SvgIcon>
  );
};
