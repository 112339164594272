import { Box } from "@mui/material";
import { Cross } from "assets/IconsComponents/Cross";
import { PersonIcon } from "assets/IconsComponents/PersonIcon";
import PseudoInput from "./PseudoInput";

const FormCard: React.FC<{
  primary: string;
  secondary: string;
  textColor: string;
  spacing: (size: number) => number;
}> = ({ primary, secondary, textColor, spacing }) => {
  return (
    <Box
      sx={{
        width: spacing(375),
        height: spacing(812),
        paddingX: spacing(3),
        paddingTop: spacing(8),
        paddingBottom: spacing(5),
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mb: spacing(4.25),
          width: "100%",
          fontSize: "2.3rem",
          fontWeight: 500,
          lineHeight: "3.8rem",
        }}
      >
        Your main details
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: spacing(3.75),
          width: spacing(72),
          height: spacing(72),
          background: secondary,
          borderRadius: spacing(32),
          svg: {
            width: spacing(30),
            height: spacing(30),
          },
        }}
      >
        <PersonIcon />
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            height: spacing(25),
            width: spacing(25),
            borderRadius: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme => theme.palette.common.white,
            svg: {
              width: spacing(8),
              height: spacing(8),
              transform: "rotate(45deg)",
              path: { fill: secondary },
            },
          }}
        >
          <Cross />
        </Box>
      </Box>
      <PseudoInput
        primary={primary}
        textColor={textColor}
        spacing={spacing}
        label={"First name*"}
        text="First name"
        mb={4}
      />
      <PseudoInput
        primary={primary}
        textColor={textColor}
        spacing={spacing}
        label={"Last name*"}
        text="Last name"
        mb={4}
      />
      <PseudoInput
        primary={primary}
        textColor={textColor}
        spacing={spacing}
        label={"Bio (about)"}
        text="Your bio"
        mb={4}
      />
      <Box width={"100%"} marginBottom={"auto"}>
        <Box
          sx={{
            mb: spacing(0.625),
            fontWeight: 600,
            fontSize: "1.2rem",
            lineHeight: "1.4rem",
            color: textColor,
          }}
        >
          Add Boat(s)
        </Box>
        <Box
          sx={{
            height: spacing(49),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: spacing(1.25),
            backgroundColor: primary + "12",
            border: `1px dashed ${primary}`,
            width: "100%",
            boxSizing: "border-box",
            paddingY: spacing(1.625),
            fontSize: `${spacing(1.4)}rem`,
            lineHeight: `${spacing(2.3)}rem`,
            borderRadius: spacing(10),
            fontWeight: 400,
            color: textColor,

            "& svg": {
              width: spacing(8),
              height: spacing(8),
              path: { fill: primary },
              transform: "rotate(45deg)",
            },
          }}
        >
          <Cross />
          Add Boat(s)
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: spacing(40),
          width: `${spacing(248)}px`,
          backgroundColor: secondary,
          borderRadius: spacing(32),

          color: theme => theme.palette.common.white,
          fontSize: `${spacing(1.6)}rem`,
          lineHeight: `${spacing(1.9)}rem`,
          fontWeight: 600,
        }}
      >
        Save
      </Box>
    </Box>
  );
};

export default FormCard;
