import { Box, Typography } from "@mui/material";
import { Email } from "assets/IconsComponents/Email";
import { RecoveryMail } from "assets/IconsComponents/RecoveryMail";
import Button from "components/Button";
import { FormInput } from "components/Input";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Login from "singletons/Login";
import { emailValidationRules } from "utils/constants";

const ForgotPassword: React.FC = () => {
  const [isSubmited, setSubmited] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: { email: "" },
  });

  const onSubmit: SubmitHandler<{
    email: string;
  }> = values => {
    Login.resetPassword(values, () => {
      setSubmited(true);
    });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        background: theme => theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            background: theme => theme.palette.common.white,
            width: 440,
            height: 440,
            padding: "56px",
            borderRadius: 40,
            justifyContent: "center",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "center",
            display: "flex",
            gap: "20px",
          }}
        >
          {isSubmited ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                  flexGrow: 1,
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    "& svg": {
                      width: "80px",
                      height: "65px",
                    },
                  }}
                >
                  <RecoveryMail />
                </Box>
                <Typography
                  color={"text.primary"}
                  fontSize={"2.3rem"}
                  fontWeight={500}
                  width={328}
                  textAlign={"center"}
                >
                  Please check your mail to confirm!
                </Typography>
              </Box>
              <Box sx={{ justifySelf: "end" }}>
                <Button
                  title={"Back to the login"}
                  onClick={() => {
                    navigate("/login");
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Typography
                color={"text.primary"}
                fontSize={"2.3rem"}
                fontWeight={500}
                width={328}
                textAlign={"center"}
              >
                Forgot the password?
              </Typography>
              <Typography
                color={"text.primary"}
                fontSize={"1.6rem"}
                fontWeight={400}
                width={328}
                textAlign={"center"}
                mb={"10px"}
              >
                Don’t worry! It happens. Please enter the email associated with
                your account
              </Typography>
              <FormInput
                control={control}
                name={"email"}
                rules={emailValidationRules}
                placeHolder={"Email"}
                startAdornment={<Email />}
              />

              <Button
                type="submit"
                title={"Submit"}
                isLoading={Login.isForgotPasswordPending}
              />

              <Box
                onClick={() => {
                  navigate("login");
                }}
                sx={{
                  position: "relative",
                  bottom: "-20px",
                  alignSelf: "end",
                  fontSize: "1.2rem",
                  color: theme => theme.palette.text.primary,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                Return to the login
              </Box>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default observer(ForgotPassword);
