import { Box } from "@mui/material";
import { PasswordLock } from "assets/IconsComponents/PasswordLock";
import Button from "components/Button";
import { PasswordInput } from "components/Input/PasswordInput";
import { observer } from "mobx-react-lite";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PublicNavigate from "singletons/PublicNavigate";
import User from "singletons/User";
import { passwordValidationRules } from "utils/constants";
import { useFormValues } from "utils/hooks/useFormValues";

const defaultValues = {
  current_password: "",
  new_password: "",
  new_password_confirm: "",
};

const ChangePassword: React.FC = () => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues,
  });
  const formValues = useFormValues(watch, defaultValues);

  const onSubmit: SubmitHandler<{
    current_password: string;
    new_password: string;
    new_password_confirm: string;
  }> = values => {
    if (User.userInfo?.id) {
      User.changePassword(values, User.userInfo.id, () => {
        PublicNavigate.navigate("/users");
      });
    }
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 112px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
      }}
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <PasswordInput
        control={control}
        name={"current_password"}
        rules={passwordValidationRules}
        placeHolder={"Current password"}
        startAdornment={<PasswordLock />}
      />
      <PasswordInput
        control={control}
        name={"new_password"}
        rules={passwordValidationRules}
        placeHolder={"New password"}
        startAdornment={<PasswordLock />}
      />
      <PasswordInput
        control={control}
        name={"new_password_confirm"}
        rules={{
          required: "Required",
          validate: value =>
            value === formValues.new_password || "Passwords don't match",
        }}
        placeHolder={"New password confirm"}
        startAdornment={<PasswordLock />}
      />

      <Button
        type="submit"
        title="Submit"
        isLoading={User.isUserChangePasswordPending}
      />
    </Box>
  );
};

export default observer(ChangePassword);
