import { Box } from "@mui/system";
import { PasswordEyeClosed } from "assets/IconsComponents/PasswordEyeClosed";
import { PasswordEyeOpened } from "assets/IconsComponents/PasswordEyeOpened";
import React, { useState } from "react";
import { Control, RegisterOptions } from "react-hook-form";
import { CustomInputProps, FormInput } from ".";

export const PasswordInput: React.FC<
  {
    control: Control<any>;
    name: string;
    rules?: Omit<
      RegisterOptions,
      "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    >;
  } & CustomInputProps
> = ({ ...props }) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <FormInput
      type={isPasswordShown ? "text" : "password"}
      endAdornment={
        <Box
          onClick={togglePasswordVisibility}
          sx={{
            cursor: "pointer",
            height: "19px",
            "& svg": { fill: "none", margin: 0 },
          }}
        >
          {isPasswordShown ? <PasswordEyeOpened /> : <PasswordEyeClosed />}
        </Box>
      }
      {...props}
    />
  );
};
