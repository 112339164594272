import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import { useNavigate } from "react-router-dom";
import { Menu, Typography } from "@mui/material";
import Login from "singletons/Login";
import { Profile } from "assets/IconsComponents/Profile";
import { useState } from "react";
import { routes } from "utils/constants/routes";

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenu = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          height: "64px",
          width: "100%",
          backgroundColor: theme => theme.palette.primary.main,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "end" }}>
          <Box
            sx={{
              color: theme => theme.palette.common.white,
              fontSize: "1.4rem",
              fontWeight: "600",
              cursor: "pointer",
              "& svg": {
                width: "24px",
                height: "24px",
              },
            }}
            onClick={handleMenu}
          >
            <Profile />
          </Box>
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            sx={{
              "&>div": {
                boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              },

              "&>*>ul": {
                padding: "10px 10px",
                minWidth: "100px",

                "&>*": {
                  "&:hover": { cursor: "pointer" },
                  height: "30px",
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                },
              },
            }}
          >
            <Box
              onClick={() => {
                navigate("change-password");
                handleCloseMenu();
              }}
            >
              Change password
            </Box>
            <Box
              sx={{
                color: theme => theme.palette.error.main,
                fontWeight: "600",
              }}
              onClick={Login.logout}
            >
              Logout
            </Box>
          </Menu>
        </Toolbar>
      </Box>
      <Box sx={{ display: "flex", minHeight: "calc(100vh - 64px)" }}>
        <Box
          sx={{
            borderRight: theme => `1px solid ${theme.palette.secondary.dark}`,
            width: "240px",
            flexShrink: 0,
            display: "inline-block",
            boxSizing: "border-box",
          }}
        >
          <List>
            {routes
              .filter(({ sidebarRoute }) => sidebarRoute)
              .map(({ name, path }) => (
                <ListItem key={name} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={() => {
                      navigate(path);
                    }}
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                    }}
                  >
                    <Typography
                      fontSize={"1.6rem"}
                      fontWeight={600}
                      color={theme.palette.primary.main}
                    >
                      {name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, boxSizing: "border-box", height: "100%" }}
        >
          {React.Children.map(children, children => children)}
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
