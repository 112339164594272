import { Box } from "@mui/material";
import CustomButton from "components/Button";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import MassNotificationStore from "singletons/MassNotificationStore";

const Notification: React.FC = () => {
  const [notificationText, setNotificationText] = useState("");

  const { sendMassNotification } = MassNotificationStore;

  const handleSubmit = () => {
    sendMassNotification(notificationText);
  };

  return (
    <Box>
      <Box
        sx={{
          fontSize: "1.6rem",
          fontWeight: 600,
          color: ({ palette }) => palette.primary.main,
          display: "flex",
          alignContent: "center",
          justifyContent: "end",
          lineHeight: "5rem",
          gap: 1,
          "&>*": { cursor: "pointer" },
        }}
      >
        {
          <>
            <Box onClick={handleSubmit}>Submit</Box>
          </>
        }
      </Box>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box
          sx={{
            width: "100%",
            "&>*": {
              maxWidth: "calc(100vw - 710px)",
              width: "100%",
              height: 400,
              fontSize: 14,
              fontFamily: "figtree",
            },
          }}
        >
          <textarea
            value={notificationText}
            onChange={({ target }) => setNotificationText(target.value)}
          />
        </Box>
        <Box
          sx={{
            boxSizing: "border-box",
            background: "rgba(68, 68, 68, 0.6)",
            backdropFilter: "blur(26.5px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: 375,
            height: 612,
            flexShrink: 0,
            paddingX: 4.75,
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: 0,
            },
          }}
        >
          <Box
            sx={{
              background: "#fff",
              minHeight: 200,
              borderRadius: 14,
              padding: 2.5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box textAlign={"right"}>X</Box>
            <Box sx={{ overflowWrap: "break-word", mb: 2, fontSize: 14 }}>
              <div>
                {notificationText.split("\n").map(substr => (
                  <div style={{ whiteSpace: "pre-line" }}>{substr || "\n"}</div>
                ))}
              </div>
            </Box>
            <CustomButton title="Proceed" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(Notification);
