import { makeAutoObservable, runInAction } from "mobx";
import request from "api";
import { AxiosResponse } from "axios";
import { successNotification } from "utils/notifications";

type TermsConditionsType = {
  version: string;
  text: string;
  id: number;
  created_at: string;
};

class TermsConditionsStore {
  currentTermsConditions: TermsConditionsType | null = null;
  isTermsConditionsLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getTermsConditions = (successCallback?: () => void) => {
    const id = 1;
    this.setIsTermsConditionsLoading(true);
    request({ url: `/api/admin/terms/${id}` })
      .then(({ data }: AxiosResponse<TermsConditionsType>) => {
        runInAction(() => {
          this.currentTermsConditions = data;
        });
        successCallback && successCallback();
      })
      .finally(() => {
        this.setIsTermsConditionsLoading(false);
      });
  };

  updateTermsConditions = (
    payload: Omit<TermsConditionsType, "id" | "created_at">,
    successCallback?: () => void,
  ) => {
    const id = 1;
    request({
      url: `/api/admin/terms/${id}`,
      method: "put",
      data: payload,
    }).then(() => {
      successCallback && successCallback();
      successNotification(" TermsConditions successfully updated!");
    });
  };

  setIsTermsConditionsLoading = (isLoading: boolean) => {
    this.isTermsConditionsLoading = isLoading;
  };

  clear = () => {};
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TermsConditionsStore();
