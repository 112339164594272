import { NavigateFunction } from "react-router-dom";

class PublicNavigate {
  navigate: NavigateFunction | (() => void) = () => {};

  setNavigate = (navigate: NavigateFunction) => {
    this.navigate = navigate;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PublicNavigate();
