import { Box, Typography } from "@mui/material";
import Button from "components/Button";
import { FormInput } from "components/Input";
import { observer } from "mobx-react-lite";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import User from "singletons/User";
import { emailValidationRules } from "utils/constants";

const defaultValues = { first_name: "", last_name: "", email: "" };

const AddUser: React.FC = () => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const onSubmit: SubmitHandler<{
    first_name: string;
    last_name: string;
    email: string;
  }> = values => {
    User.inviteAdmin(values, () => {
      reset(defaultValues);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "calc(100vh - 112px)",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      onSubmit={handleSubmit(onSubmit)}
      component={"form"}
    >
      <Typography
        color={"text.primary"}
        fontSize={"2rem"}
        fontWeight={400}
        width={328}
        textAlign={"center"}
        mb={"10px"}
      >
        Invite a user
      </Typography>
      <FormInput
        control={control}
        name={"first_name"}
        rules={{
          required: "Required",
        }}
        placeHolder={"First name"}
      />
      <FormInput
        control={control}
        name={"last_name"}
        rules={{
          required: "Required",
        }}
        placeHolder={"Last name"}
      />
      <FormInput
        control={control}
        name={"email"}
        rules={emailValidationRules}
        placeHolder={"Email"}
      />
      <Button
        type="submit"
        title={"Submit"}
        isLoading={User.isUserRegistrationPending}
      />
    </Box>
  );
};

export default observer(AddUser);
