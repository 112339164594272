import AddUser from "pages/AddUser";
import ChangePassword from "pages/ChangePassword";
import CustomerDetails from "pages/CustomerDetails";
import Customers from "pages/Customers";
import Branding from "pages/Branding";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Terms from "pages/Terms";
import Notification from "pages/Notification";

export const routes = [
  {
    path: "users",
    Component: Customers,
    name: "List of users",
    sidebarRoute: true,
  },
  { path: "users/:customerID", Component: CustomerDetails, name: "user info" },
  {
    path: "terms&conditions",
    Component: Terms,
    name: "Terms and Conditions",
    sidebarRoute: true,
  },
  {
    path: "privacyPolicy",
    Component: PrivacyPolicy,
    name: "Privacy Policy",
    sidebarRoute: true,
  },
  {
    path: "branding",
    Component: Branding,
    name: "Branding",
    sidebarRoute: true,
  },
  {
    path: "notifications",
    Component: Notification,
    name: "Notifications",
    sidebarRoute: true,
  },
  {
    path: "invite",
    Component: AddUser,
    name: "Manage admin users",
    sidebarRoute: true,
  },
  {
    path: "change-password",
    Component: ChangePassword,
    name: "Change password",
  },
];
