import { Box } from "@mui/material";
import Loader from "components/Loader";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import BrandingStore from "singletons/BrandingStore";
import { useThrottledState } from "utils/hooks/useThrottledState";
import FormCard from "./FormCard";
import LoginCard from "./LoginCard";
import SplashCard from "./SplashCard";

const cardSizeMultiplier = 0.7;

const spacing = (size: number) => size * cardSizeMultiplier;

function Branding() {
  const [isEditing, setIsEditing] = useState(false);
  const [primary, setPrimary] = useThrottledState("#FCAB38");
  const [secondary, setSecondary] = useThrottledState("#00A6B3");
  const [textColor, setTextColor] = useThrottledState("#231F20");

  const setColors = useCallback(
    ({
      primary,
      secondary,
      mainText,
    }: {
      primary: string;
      secondary: string;
      mainText: string;
    }) => {
      setPrimary(primary);
      setSecondary(secondary);
      setTextColor(mainText);
    },
    [setPrimary, setSecondary, setTextColor],
  );

  useEffect(() => {
    BrandingStore.getTheme(() => {
      setColors(BrandingStore.currentThemeColors);
    });

    return () => {
      BrandingStore.clear();
    };
  }, [setColors]);

  return BrandingStore.isThemeLoading ? (
    <Loader />
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          alignItems: "center",
          mb: 3.625,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "100px 1fr",
            rowGap: 1,
            fontSize: "1.6rem",
          }}
        >
          <Box>Primary:</Box>
          <input
            disabled={!isEditing}
            type="color"
            value={primary}
            onChange={event => {
              setPrimary(event.target.value);
            }}
          />
          <Box>Secondary:</Box>
          <input
            disabled={!isEditing}
            type="color"
            value={secondary}
            onChange={event => {
              setSecondary(event.target.value);
            }}
          />
          <Box>Text:</Box>
          <input
            disabled={!isEditing}
            type="color"
            value={textColor}
            onChange={event => {
              setTextColor(event.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            fontSize: "1.6rem",
            display: "flex",
            gap: 1.5,
            fontWeight: 600,
            color: ({ palette }) => palette.primary.main,
            "&>*": { cursor: "pointer" },
          }}
        >
          {isEditing ? (
            <>
              <Box
                onClick={() => {
                  setColors(BrandingStore.currentThemeColors);
                  setIsEditing(false);
                }}
                color={theme => theme.palette.secondary.dark}
              >
                Cancel
              </Box>
              <Box
                onClick={() => {
                  setColors(BrandingStore.currentThemeColors);
                }}
              >
                Reset
              </Box>
              <Box
                onClick={() => {
                  setPrimary("#FCAB38");
                  setSecondary("#00A6B3");
                  setTextColor("#231F20");
                }}
              >
                Set initial colors
              </Box>
              <Box
                onClick={() => {
                  BrandingStore.updateTheme(
                    {
                      primary,
                      secondary,
                      mainText: textColor,
                    },
                    () => {
                      setIsEditing(false);
                    },
                  );
                }}
              >
                Submit
              </Box>
            </>
          ) : (
            <Box
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          rowGap: 1,
          columnGap: 6,
        }}
      >
        <SplashCard secondary={secondary} spacing={spacing} />
        <LoginCard
          primary={primary}
          secondary={secondary}
          textColor={textColor}
          spacing={spacing}
        />
        <FormCard
          primary={primary}
          secondary={secondary}
          textColor={textColor}
          spacing={spacing}
        />
      </Box>
    </Box>
  );
}

export default observer(Branding);
