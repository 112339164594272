import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";

import { Box } from "@mui/material";

const options = [
  "blockType",
  "inline",
  "fontSize",
  "list",
  "textAlign",
  "history",
  "colorPicker",
];

const colorPickerColors = [
  "rgb(0, 166, 179)",
  "rgb(252, 171, 56)",
  "rgb(35, 31, 32)",
  "#9B9B9B",
  "rgb(97,189,109)",
  "rgb(26,188,156)",
  "rgb(84,172,210)",
  "rgb(44,130,201)",
  "rgb(147,101,184)",
  "rgb(71,85,119)",
  "rgb(204,204,204)",
  "rgb(65,168,95)",
  "rgb(0,168,133)",
  "rgb(61,142,185)",
  "rgb(41,105,176)",
  "rgb(85,57,130)",
  "rgb(40,50,78)",
  "rgb(0,0,0)",
  "rgb(247,218,100)",
  "rgb(251,160,38)",
  "rgb(235,107,86)",
  "rgb(226,80,65)",
  "rgb(163,143,132)",
  "rgb(239,239,239)",
  "rgb(255,255,255)",
  "rgb(250,197,28)",
  "rgb(243,121,52)",
  "rgb(209,72,65)",
  "rgb(184,49,47)",
  "rgb(124,112,107)",
  "rgb(209,213,216)",
];

const getWrappedContent = (content: string) => {
  return `<!DOCTYPE html>
  <html
    xmlns="http://www.w3.org/1999/xhtml"
    xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
  >
    <head>
      <title>The Voy</title>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <style type="text/css">
        body {
          margin: 0;
          padding: 0;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          font-size: 14px;
          color: rgb(35, 31, 32)
        }
  
        p {
          margin: 0;
        }
  
        h1 {
          margin: 0;
        }
  
        h3 {
          margin: 0;
          font-weight: 600;
          font-size: 16px;
          line-height: 50px;
        }
      </style>
    </head>
  
    <body style="max-width: 375px">
    <div>
    ${content}
    </div>
    </body>
  </html>`;
};

const getEditorState = (HTMLString: string) => {
  const parser = new DOMParser();
  const editorContentDOM = parser.parseFromString(HTMLString, "text/html");
  const editorContent = editorContentDOM.querySelector("body>*")?.innerHTML!;

  const blocksFromHTML = htmlToDraft(editorContent);
  return ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
};

const createEditor = (HTMLString?: string) => {
  return HTMLString
    ? EditorState.createWithContent(getEditorState(HTMLString))
    : EditorState.createEmpty();
};

const TextEditor: React.FC<{
  unparsedHTMLString?: string;
  setCurrentLayout?: (currentLayout: string) => void;
}> = ({ unparsedHTMLString, setCurrentLayout }) => {
  const [editorState, setEditorState] = useState(
    createEditor(unparsedHTMLString),
  );

  useEffect(() => {
    setCurrentLayout &&
      setCurrentLayout(
        getWrappedContent(
          draftToHtml(convertToRaw(editorState.getCurrentContent())),
        ),
      );
  }, [editorState, setCurrentLayout]);

  return (
    <Box
      sx={{
        "& .public-DraftStyleDefault-block": {
          margin: "0px !important",
        },
      }}
    >
      <Editor
        defaultEditorState={editorState}
        onEditorStateChange={setEditorState}
        toolbarStyle={{ borderRadius: 5 }}
        customStyleMap={{
          BOLD: { fontWeight: 600 },
          "header-three": { fontWeight: 600, margin: 0, fontSize: 16 },
        }}
        editorStyle={{
          border: "1px solid black",
          padding: 1,
          fontSize: "1.4rem",
          minHeight: 300,
          color: "rgb(35, 31, 32)",
        }}
        toolbar={{
          options,
          blockType: {
            inDropdown: false,
            options: ["Normal", "H3"],
          },
          colorPicker: { colors: colorPickerColors },
        }}
      />
    </Box>
  );
};

export default TextEditor;
