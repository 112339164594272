import { makeAutoObservable, runInAction } from "mobx";
import { defaultColors } from "utils/constants";
import request from "api";
import { AxiosResponse } from "axios";

type ThemeColors = {
  primary: string;
  secondary: string;
  mainText: string;
};

class BrandingStore {
  currentThemeColors: ThemeColors = defaultColors;
  isThemeLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getTheme = (successCallback?: () => void) => {
    const id = 1;
    this.setIsThemeLoading(true);
    request({ url: `/api/admin/app/settings/${id}` })
      .then(({ data }: AxiosResponse<{ visual_config: ThemeColors }>) => {
        runInAction(() => {
          this.currentThemeColors = data.visual_config;
        });
        successCallback && successCallback();
      })
      .finally(() => {
        this.setIsThemeLoading(false);
      });
  };

  updateTheme = (payload: ThemeColors, successCallback?: () => void) => {
    const id = 1;
    request({
      url: `/api/admin/app/settings/${id}`,
      method: "put",
      data: { visual_config: payload },
    }).then(() => {
      successCallback && successCallback();
    });
  };

  setIsThemeLoading = (isLoading: boolean) => {
    this.isThemeLoading = isLoading;
  };

  clear = () => {};
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new BrandingStore();
