import { SvgIcon } from "@mui/material";

export const PersonIcon = () => {
  return (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22">
      <path
        d="M15.0848 11.7803C16.1685 10.9323 16.9596 9.76943 17.3478 8.45351C17.7361 7.13759 17.7023 5.73403 17.2512 4.4381C16.8 3.14217 15.9539 2.01831 14.8306 1.22288C13.7073 0.42745 12.3626 0 10.9836 0C9.60463 0 8.25995 0.42745 7.13662 1.22288C6.0133 2.01831 5.1672 3.14217 4.71604 4.4381C4.26488 5.73403 4.23109 7.13759 4.61937 8.45351C5.00765 9.76943 5.7987 10.9323 6.88245 11.7803C5.02541 12.5202 3.40508 13.7474 2.1942 15.3311C0.983314 16.9148 0.227271 18.7956 0.00666779 20.7731C-0.00930062 20.9174 0.00348032 21.0635 0.0442806 21.203C0.0850808 21.3424 0.153101 21.4725 0.244459 21.5859C0.428964 21.8147 0.697323 21.9613 0.990502 21.9934C1.28368 22.0254 1.57766 21.9404 1.80778 21.7569C2.03789 21.5734 2.18528 21.3065 2.21753 21.0149C2.46027 18.8659 3.49064 16.8811 5.11179 15.4398C6.73294 13.9985 8.83122 13.2018 11.0057 13.2018C13.1802 13.2018 15.2785 13.9985 16.8996 15.4398C18.5208 16.8811 19.5512 18.8659 19.7939 21.0149C19.8239 21.2851 19.9536 21.5345 20.1577 21.7152C20.3618 21.8959 20.626 21.995 20.8993 21.9934H21.0209C21.3107 21.9602 21.5755 21.8145 21.7578 21.588C21.94 21.3615 22.0248 21.0725 21.9937 20.7841C21.7721 18.801 21.0119 16.9154 19.7948 15.3292C18.5777 13.7431 16.9495 12.5163 15.0848 11.7803ZM10.9836 10.9997C10.1091 10.9997 9.25417 10.7418 8.52703 10.2586C7.79988 9.7754 7.23313 9.08861 6.89846 8.28508C6.56379 7.48154 6.47623 6.59736 6.64684 5.74434C6.81745 4.89131 7.23858 4.10776 7.85697 3.49276C8.47536 2.87776 9.26324 2.45895 10.121 2.28927C10.9787 2.11959 11.8678 2.20668 12.6757 2.53951C13.4837 2.87234 14.1743 3.43598 14.6601 4.15914C15.146 4.8823 15.4053 5.7325 15.4053 6.60224C15.4053 7.76852 14.9395 8.88703 14.1102 9.71172C13.281 10.5364 12.1563 10.9997 10.9836 10.9997Z"
        fill="white"
      />
    </SvgIcon>
  );
};
