import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import Login from "singletons/Login";
import { accessTokenKey } from "utils/constants";

const BASE_URL = "https://be-admin.voysailing.com";

class RequestController {
  isRefreshPending: boolean = false;
  #privateRequest: AxiosInstance;
  refreshRequestResult: Promise<void> | null = null;
  constructor({ privateRequest }: { privateRequest: AxiosInstance }) {
    this.#privateRequest = privateRequest;
  }

  privateRequest = ({ url, method, ...rest }: AxiosRequestConfig) => {
    return this.#privateRequest({ url, method, ...rest })
      .then(data => Promise.resolve(data))
      .catch((e: AxiosError) => {
        if (e.response?.status === 401) {
          if (!this.refreshRequestResult) {
            this.refreshRequestResult = Login.refreshToken().finally(() => {
              this.refreshRequestResult = null;
            });
          }
          if (this.refreshRequestResult) {
            this.refreshRequestResult.then(() => {
              this.#privateRequest({ url, method, ...rest });
            });
          }
        }
        return Promise.reject(e);
      });
  };
}

const instance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    Authorization: `Bearer *token*`,
  },
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(accessTokenKey);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

const publicAxios = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default new RequestController({ privateRequest: instance })
  .privateRequest;

export { publicAxios, BASE_URL };
