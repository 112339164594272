import { debounce } from "@mui/material";
import { useCallback, useEffect } from "react";

export const useDebounce: <FuncType extends (...args: Array<any>) => any>(
  debounceFunc: FuncType,
  time: number,
) => FuncType = (debounceFunc, time) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const f = useCallback(debounce(debounceFunc, time), []);

  useEffect(() => () => f.clear(), [f]);

  return f;
};
