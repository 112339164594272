import { SvgIcon } from "@mui/material";

export const CheckboxSelected = () => {
  return (
    <SvgIcon viewBox="0 0 19 19">
      <g clip-path="url(#clip0_2026_14937)">
        <rect width="19" height="19" rx="1" fill="#00A6B3" />
        <path
          d="M5 10.32L7.51429 13.2L13.8 6"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2026_14937">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
