import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Paper,
  Box,
  Menu,
} from "@mui/material";
import Checkbox from "components/Checkbox";
import CustomInput from "components/Input";
import Loader from "components/Loader";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import CustomersStore from "singletons/CustomersStore";

import { useDebounce } from "utils/hooks/useDebounce";

const Customers: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActiveChecked, setActive] = useState(false);
  const [isInactiveChecked, setInactive] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const debouncedSetSearchValue = useDebounce(
    CustomersStore.setSearchValue,
    500,
  );

  const handleSearch = (value: string) => {
    setSearchInputValue(value);
    debouncedSetSearchValue(value);
  };

  const handleMenu = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isActiveChecked === isInactiveChecked) {
      CustomersStore.getCustomers(CustomersStore.searchValue);
    } else {
      CustomersStore.getCustomers(CustomersStore.searchValue, isActiveChecked);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveChecked, isInactiveChecked, CustomersStore.searchValue]);

  useEffect(() => () => CustomersStore.clear(), []);

  return (
    <Box
      sx={{
        height: "calc(100vh - 112px)",
      }}
    >
      <Box
        sx={{
          marginBottom: "35px",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",

          "&>*>div": { marginBottom: 0 },
        }}
      >
        <CustomInput
          value={searchInputValue}
          onChange={e => {
            handleSearch(e.currentTarget.value);
          }}
          placeHolder="Search"
        />

        <Box
          onClick={handleMenu}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "1.6rem",
            color: theme => theme.palette.secondary.main,
            fontWeight: 600,
          }}
        >
          Filter Status
        </Box>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          sx={{
            "&>div": {
              boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            },

            "&>*>ul": {
              padding: "10px 10px",
              minWidth: "100px",

              "&>*": {
                cursor: "pointer",
                height: "30px",
                fontSize: "1.4rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                "&>*": {
                  cursor: "pointer",
                },
              },
            },
          }}
        >
          <label
            onClick={() => {
              setActive(!isActiveChecked);
            }}
          >
            <Checkbox value={isActiveChecked} />
            Show Active
          </label>
          <label
            onClick={() => {
              setInactive(!isInactiveChecked);
            }}
          >
            <Checkbox value={isInactiveChecked} />
            Show Inactive
          </label>
        </Menu>
      </Box>
      {CustomersStore.isCustomersLoading ? (
        <Box height={"calc(100vh - 192px)"}>
          <Loader />
        </Box>
      ) : (
        <Paper
          id={"tableScrollWrapper"}
          sx={{
            width: "100%",
            height: "calc(100% - 79px)",
            overflowX: "auto",
            position: "relative",
          }}
        >
          <InfiniteScroll
            dataLength={CustomersStore.customers.length}
            next={() => {
              CustomersStore.getMoreCustomers(
                CustomersStore.searchValue,
                isActiveChecked === isInactiveChecked ? null : isActiveChecked,
              );
            }}
            loader={
              <Box
                sx={{
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            }
            scrollThreshold={0.7}
            hasMore={CustomersStore.hasMore}
            style={{
              overflow: "none",
              marginRight: 4,
            }}
            scrollableTarget={"tableScrollWrapper"}
          >
            <Table
              sx={{
                td: { fontSize: "1.6rem" },
                th: { fontSize: "1.6rem" },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CustomersStore.customers.length ? (
                  CustomersStore.customers.map(
                    (
                      { id, email, first_name, last_name, is_active },
                      index,
                    ) => (
                      <TableRow key={id} sx={{ height: "80px" }}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          sx={{
                            "& a": {
                              textDecoration: "none",
                              color: theme => theme.palette.secondary.main,
                            },
                          }}
                        >
                          <Link to={`${id}`}> {email}</Link>
                        </TableCell>
                        <TableCell>
                          {is_active ? (
                            <Box color={"green"} fontWeight={600}>
                              Active
                            </Box>
                          ) : (
                            <Box color={"red"} fontWeight={600}>
                              Inactive
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>{first_name}</TableCell>
                        <TableCell>{last_name}</TableCell>
                      </TableRow>
                    ),
                  )
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      top: "45%",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "3rem",
                      fontWeight: 500,
                      position: "absolute",
                    }}
                  >
                    No users found
                  </Box>
                )}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </Paper>
      )}
    </Box>
  );
};

export default observer(Customers);
