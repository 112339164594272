import { SvgIcon } from "@mui/material";

export const RecoveryMail = () => {
  return (
    <SvgIcon width={"84px"} height={"69px"} viewBox="0 0 84 69">
      <path
        d="M18.0009 18.2499L26.6365 25.5587C33.983 31.7765 37.6563 34.8854 42.0009 34.8854C46.3455 34.8854 50.0188 31.7765 57.3653 25.5587L66.0009 18.2499M34 67H50C65.0849 67 72.6274 67 77.3137 62.2405C82 57.481 82 49.8206 82 34.5C82 19.1794 82 11.519 77.3137 6.75952C72.6274 2 65.0849 2 50 2H34C18.9151 2 11.3726 2 6.68629 6.75952C2 11.519 2 19.1794 2 34.5C2 49.8206 2 57.481 6.68629 62.2405C11.3726 67 18.9151 67 34 67Z"
        stroke="#FCAB38"
        fill="transparent"
        stroke-width="4"
        stroke-linecap="round"
      />
    </SvgIcon>
  );
};
