import { Box, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import PublicNavigate from "singletons/PublicNavigate";

import ProjectRoutes from "components/Routes";
import { PrivateRoutes } from "components/Routes/PrivateRoutes";
import CompleteRegistration from "pages/AddUser/CompleteRegistration";
import ForgotPassword from "pages/ForgotPassword";
import ForgotPasswordConfirm from "pages/ForgotPassword/ForgotPasswordConfirm";
import LoginPage from "pages/Login";

import "react-toastify/dist/ReactToastify.css";
import theme from "theme/MUITheme";
import "./theme/CSS/notifications.css";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    PublicNavigate.setNavigate(navigate);
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        limit={5}
        hideProgressBar
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Box sx={{ height: "fit-content", minHeight: "100vh" }}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/registration-complete"
            element={<CompleteRegistration />}
          />
          <Route
            path="/forgot-password/confirm"
            element={<ForgotPasswordConfirm />}
          />
          <Route element={<PrivateRoutes />}>
            <Route path="/*" element={<ProjectRoutes />} />
          </Route>
          <Route path="*" element={<>404</>} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
}

export default App;
