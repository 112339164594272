import { SvgIcon } from "@mui/material";

export const Profile = () => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 9.34374 2.01412 10.5908 2.72168 11.6204C4.31528 10.8387 6.10735 10.4 8 10.4C9.89266 10.4 11.6847 10.8387 13.2783 11.6204C13.9859 10.5908 14.4 9.34374 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6ZM14.1229 13.1491C15.2943 11.7576 16 9.96124 16 8C16 3.58173 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 9.96163 0.706014 11.7583 1.8778 13.1499C1.88336 13.1567 1.88901 13.1634 1.89476 13.17C3.36221 14.9012 5.55278 16 8 16C10.4469 16 12.6371 14.9015 14.1046 13.1708C14.1108 13.1637 14.1169 13.1565 14.1229 13.1491ZM12.1589 12.8647C10.8859 12.3086 9.47976 12 8 12C6.52023 12 5.11407 12.3086 3.84108 12.8647C4.95967 13.8219 6.41232 14.4 8 14.4C9.58769 14.4 11.0403 13.8219 12.1589 12.8647ZM8 4.8C7.11632 4.8 6.4 5.51634 6.4 6.4C6.4 7.28369 7.11631 8 8 8C8.88369 8 9.6 7.28369 9.6 6.4C9.6 5.51634 8.88368 4.8 8 4.8ZM4.8 6.4C4.8 4.6327 6.23264 3.2 8 3.2C9.76736 3.2 11.2 4.6327 11.2 6.4C11.2 8.16735 9.76735 9.6 8 9.6C6.23265 9.6 4.8 8.16735 4.8 6.4Z"
      />
    </SvgIcon>
  );
};
