// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Fonts/Figtree-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html {\n  font-size: 62.5%; /* 62.5% of 16px = 10px */\n  font-family: \"Figtree\";\n}\n\nbody {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n@font-face {\n  font-family: \"Figtree\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/theme/CSS/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,yBAAyB;EAC3C,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;EACtB,4CAAgD;AAClD","sourcesContent":["html {\n  font-size: 62.5%; /* 62.5% of 16px = 10px */\n  font-family: \"Figtree\";\n}\n\nbody {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n@font-face {\n  font-family: \"Figtree\";\n  src: url(../Fonts/Figtree-VariableFont_wght.ttf);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
