import { Box } from "@mui/material";

const PseudoInput: React.FC<{
  primary: string;
  textColor: string;
  spacing: (size: number) => number;
  label?: string;
  StartIcon?: () => JSX.Element;
  text?: string;
  mb?: number;
}> = ({ primary, StartIcon, text, mb = 0, label, textColor, spacing }) => (
  <Box width={"100%"}>
    {label && (
      <Box
        sx={{
          mb: 0.625,
          fontWeight: 600,
          fontSize: "1.2rem",
          lineHeight: "1.4rem",
          color: textColor,
        }}
      >
        {label}
      </Box>
    )}
    <Box
      sx={{
        height: spacing(49),
        display: "flex",
        alignItems: "center",
        marginBottom: spacing(mb),
        gap: spacing(1.25),
        backgroundColor: primary + "12",
        borderBottom: `1px solid ${primary}`,
        boxSizing: "border-box",
        padding: `${spacing(14)}px ${spacing(16)}px ${spacing(12)}px ${spacing(
          16,
        )}px`,
        fontSize: `${spacing(1.4)}rem`,
        lineHeight: `${spacing(2.3)}rem`,
        borderRadius: spacing(10),
        fontWeight: 400,
        color: textColor,

        "& svg": {
          width: spacing(18),
          height: spacing(18),
          path: { fill: primary },
        },
      }}
    >
      {StartIcon && <StartIcon />}
      {text}
    </Box>
  </Box>
);

export default PseudoInput;
