import { Box } from "@mui/material";
import CustomInput from "components/Input";
import Loader from "components/Loader";
import TextEditor from "components/TextEditor";
import { observer } from "mobx-react-lite";
import React, { PropsWithChildren, useEffect, useState } from "react";
import PolicyStore from "singletons/PolicyStore";

export const PreviewWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: 375,
        height: 812,
        flexShrink: 0,
        paddingX: 4,
        paddingY: 3.75,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: 0,
        },
      }}
    >
      {children}
    </Box>
  );
};

const PrivacyPolicy: React.FC = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [currentVersion, setCurrentVersion] = useState("");
  const [isPolicyEditing, setPolicyEditing] = useState(false);

  const { getPolicy, updatePolicy, currentPrivacyPolicy, isPolicyLoading } =
    PolicyStore;

  useEffect(() => {
    getPolicy(() => {
      if (PolicyStore.currentPrivacyPolicy) {
        setPrivacyPolicy(PolicyStore.currentPrivacyPolicy.text);
        setCurrentVersion(PolicyStore.currentPrivacyPolicy.version);
      }
    });
  }, [getPolicy]);

  const handleSubmit = () => {
    updatePolicy({ version: currentVersion, text: privacyPolicy }, () => {
      getPolicy(() => {
        if (PolicyStore.currentPrivacyPolicy) {
          setPrivacyPolicy(PolicyStore.currentPrivacyPolicy.text);
          setCurrentVersion(PolicyStore.currentPrivacyPolicy.version);
          setPolicyEditing(false);
        }
      });
    });
  };

  return isPolicyLoading || !currentPrivacyPolicy ? (
    <Loader />
  ) : (
    <Box>
      <Box
        sx={{
          fontSize: "1.6rem",
          fontWeight: 600,
          color: ({ palette }) => palette.primary.main,
          display: "flex",
          alignContent: "center",
          justifyContent: "end",
          lineHeight: "5rem",
          gap: 1,
          "&>*": { cursor: "pointer" },
        }}
      >
        {isPolicyEditing ? (
          <>
            <Box
              color={theme => theme.palette.secondary.dark}
              onClick={() => {
                setPolicyEditing(false);
                setPrivacyPolicy(currentPrivacyPolicy.text);
                setCurrentVersion(currentPrivacyPolicy.version);
              }}
            >
              Cancel
            </Box>
            <Box onClick={handleSubmit}>Submit</Box>
          </>
        ) : (
          <Box>
            <Box
              onClick={() => {
                setPolicyEditing(true);
              }}
            >
              Edit
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "1.8rem",
          marginBottom: 2,
          height: 45,
          gap: 2,
          textAlign: "center",
          "&&>*>*": {
            mb: 0,
          },
        }}
      >
        Current version:
        {isPolicyEditing ? (
          <CustomInput
            value={currentVersion}
            onChange={e => setCurrentVersion(e.target.value)}
          />
        ) : (
          <Box sx={{ fontWeight: 600 }}>{currentPrivacyPolicy.version}</Box>
        )}
      </Box>
      <Box sx={{ display: "flex", gap: 3 }}>
        {isPolicyEditing && (
          <TextEditor
            setCurrentLayout={setPrivacyPolicy}
            unparsedHTMLString={currentPrivacyPolicy.text}
          />
        )}
        <PreviewWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              paddingBottom: 4,
              color: theme => theme.palette.text.primary,
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Box
                sx={theme => ({
                  width: 28,
                  height: 28,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: `1.5px solid ${theme.palette.secondary.dark}`,
                  borderRadius: "100%",
                  color: theme.palette.secondary.dark,
                  fontSize: "2rem",
                })}
              >
                ←
              </Box>
              <Box
                sx={{
                  fontSize: "2.3rem",
                  lineHeight: "3.8rem",
                  fontWeight: 500,
                }}
              >
                Privacy Policy
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: "1.4rem",
              color: theme => theme.palette.secondary.dark,
            }}
          >
            {currentVersion}
          </Box>
          <div
            dangerouslySetInnerHTML={{
              __html: privacyPolicy,
            }}
          />
        </PreviewWrapper>
      </Box>
    </Box>
  );
};

export default observer(PrivacyPolicy);
