import { publicAxios } from "api";
import { AxiosResponse } from "axios";
import { makeAutoObservable } from "mobx";
import { accessTokenKey, refreshTokenKey } from "utils/constants";
import { errorNotification, successNotification } from "utils/notifications";
import PublicNavigate from "./PublicNavigate";

class Login {
  isLoginPending = false;
  isForgotPasswordPending = false;
  isForgotPasswordConfirmPending = false;

  constructor() {
    makeAutoObservable(this);
  }

  getAccessToken = (value: { username: string; password: string }) => {
    const payload = new URLSearchParams();
    payload.append("username", value.username);
    payload.append("password", value.password);

    this.setIsLoginPending(true);

    publicAxios
      .post(`/api/admin/auth/access-token`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(
        ({
          data: { access_token, refresh_token },
        }: AxiosResponse<{
          access_token: string;
          refresh_token: string;
          token_type: string;
        }>) => {
          localStorage.setItem(accessTokenKey, access_token);
          localStorage.setItem(refreshTokenKey, refresh_token);
          PublicNavigate.navigate("/users");
        },
      )
      .catch(e => {
        errorNotification(e.response.data.detail);
      })
      .finally(() => {
        this.setIsLoginPending(false);
      });
  };

  resetPassword = (
    payload: { email: string },
    successCallback?: () => void,
  ) => {
    this.setForgotPasswordPending(true);

    publicAxios
      .post(`/api/admin/reset-password`, payload)
      .then(() => {
        successCallback && successCallback();
      })
      .catch(() => {
        errorNotification("This e-mail account does not exist in Voy");
      })
      .finally(() => {
        this.setForgotPasswordPending(false);
      });
  };

  confirmPasswordReset = (
    payload: { token: string; password: string; password_confirm: string },
    successCallback?: () => void,
  ) => {
    this.setForgotPasswordConfirmPending(true);

    publicAxios
      .post(`/api/admin/reset-password/confirm`, payload)
      .then(() => {
        successNotification("Password successfully changed");
        successCallback && successCallback();
      })
      .catch(() => {
        errorNotification("This token expired");
      })
      .finally(() => {
        this.setForgotPasswordConfirmPending(false);
      });
  };

  refreshToken = (finalCallback?: () => void) => {
    const payload = { sub: localStorage.getItem(refreshTokenKey) };

    return publicAxios
      .post(`/api/admin/auth/refresh-token`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(
        ({
          data: { access_token, refresh_token },
        }: AxiosResponse<{
          access_token: string;
          refresh_token: string;
          token_type: string;
        }>) => {
          localStorage.setItem(accessTokenKey, access_token);
          localStorage.setItem(refreshTokenKey, refresh_token);
        },
      )
      .catch(() => {
        errorNotification("Your token is expired. You need to login again");
        this.logout();
      })
      .finally(() => {
        finalCallback && finalCallback();
      });
  };

  setIsLoginPending = (isPending: boolean) => {
    this.isLoginPending = isPending;
  };

  setForgotPasswordPending = (isPending: boolean) => {
    this.isForgotPasswordPending = isPending;
  };
  setForgotPasswordConfirmPending = (isPending: boolean) => {
    this.isForgotPasswordConfirmPending = isPending;
  };

  logout = () => {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
    PublicNavigate.navigate("login");
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Login();
