import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { accessTokenKey } from "utils/constants";

export const PrivateRoutes: React.FC<{}> = () => {
  if (!localStorage.getItem(accessTokenKey)) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
