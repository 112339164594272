import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button/Button";

const CustomButton: React.FC<{
  title: string;
  type?: "submit" | "reset" | "button";
  isLoading?: boolean;
  width?: number;
  onClick?: () => void;
}> = ({ title, type, isLoading = false, width, onClick }) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      variant={"contained"}
      disabled={isLoading}
      disableRipple
      disableElevation
      disableFocusRipple
      disableTouchRipple
      sx={[
        theme => ({
          height: "40px",
          width: `${width || 248}px`,
          borderRadius: 32,
          color: theme.palette.common.white,
          fontFamily: "figtree",
          fontSize: "1.6rem",
          fontWeight: 600,
          lineHeight: "1.9rem",
          textTransform: "none",
          backgroundColor: theme.palette.primary.main,

          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
        }),
        isLoading && {
          "&.Mui-disabled": theme => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          }),
        },
      ]}
    >
      {isLoading ? (
        <>
          <CircularProgress
            size={26}
            sx={theme => ({
              marginRight: "7px",
              "& circle": {
                color: theme.palette.common.white,
              },
            })}
          />
          Loading...
        </>
      ) : (
        title
      )}
    </Button>
  );
};

export default CustomButton;
