import React, { useEffect } from "react";
import { Paper, Box, CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomerStore from "singletons/CustomerStore";
import { AltIcon } from "assets/IconsComponents/AltIcon";
import Loader from "components/Loader";

const getDate = (initialDate: string) => {
  const startDate = new Date(initialDate);

  const month = startDate.toLocaleString("default", { month: "long" });
  const date = startDate.getUTCDate();
  const year = startDate.getUTCFullYear();

  return `${month} ${date}, ${year}`;
};

const TripsList: React.FC<{ customerID: string }> = ({ customerID }) => {
  const {
    isCustomerTripsLoading: isTripsLoading,
    trips,
    hasMoreTrips,
    getCustomerTrips,
    getMoreCustomerTrips,
  } = CustomerStore;

  useEffect(() => {
    getCustomerTrips(customerID);
  }, [customerID, getCustomerTrips]);

  return (
    <Paper
      id={"tripsScrollWrapper"}
      sx={{
        width: "100%",
        height: "calc(100% - 364px)",
        overflowX: "auto",
        boxShadow: "none",
      }}
    >
      {isTripsLoading ? (
        <Loader />
      ) : trips.length ? (
        <InfiniteScroll
          dataLength={trips.length}
          next={() => {
            getMoreCustomerTrips(customerID);
          }}
          loader={
            <Box
              sx={{
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
            </Box>
          }
          scrollThreshold={0.7}
          hasMore={hasMoreTrips}
          style={{
            overflow: "none",
            marginRight: 4,
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "space-between",
            display: "grid",
            gridTemplateColumns: "repeat(4,1fr)",
          }}
          scrollableTarget={"tripsScrollWrapper"}
        >
          {trips.map(({ id, media, name, start_time, end_time }) => {
            const startDate = getDate(start_time);
            const endDate = end_time ? getDate(end_time) : null;
            return (
              <Box
                key={id}
                sx={{
                  height: "153px",
                  minWidth: "153px",
                  flexGrow: 1,
                  flexBasis: 0,
                }}
              >
                <Box
                  sx={{
                    height: "104px",
                    width: "100%",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    backgroundColor: ({ palette }) => palette.primary.main,
                  }}
                >
                  {media ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        img: {
                          width: "inherit",
                          height: "inherit",
                          borderRadius: "8px",
                          objectFit: "cover",
                        },
                      }}
                    >
                      <img src={media.file_url} alt={"BoatIMG"} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "& svg": { width: "30px", height: "30px" },
                      }}
                    >
                      <AltIcon />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: 600,
                    lineHeight: "2rem",
                  }}
                >
                  {name}
                </Box>
                <Box
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 400,
                    lineHeight: "1.5rem",
                  }}
                >
                  {startDate} {endDate && `— ${endDate}`}
                </Box>
              </Box>
            );
          })}
        </InfiniteScroll>
      ) : (
        <Box
          sx={{
            height: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.6rem",
            fontWeight: 300,
          }}
        >
          The user hasn't added trips yet
        </Box>
      )}
    </Paper>
  );
};

export default observer(TripsList);
