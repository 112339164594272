import { Box, SxProps, Theme, Typography } from "@mui/material";
import Input, { InputProps } from "@mui/material/Input/Input";
import { Controller, Control } from "react-hook-form";
import {
  ControllerRenderProps,
  RegisterOptions,
} from "react-hook-form/dist/types";

export type CustomInputProps = {
  helperText?: string;
  error?: boolean;
  field?: ControllerRenderProps;
  placeHolder?: string;
  label?: string;
  customStyles?: SxProps<Theme>;
} & InputProps;

const CustomInput: React.FC<CustomInputProps> = ({
  helperText,
  error,
  field,
  placeHolder,
  label,
  customStyles,
  ...props
}) => {
  return (
    <Box>
      {label && (
        <Typography
          color={"text.primary"}
          fontSize={"1.2rem"}
          lineHeight={"1.4rem"}
          fontWeight={600}
          sx={{ marginBottom: "5px", marginLeft: "2px" }}
        >
          {label}
        </Typography>
      )}
      <Input
        {...props}
        error={error}
        disableUnderline
        placeholder={placeHolder}
        sx={
          customStyles || [
            theme => ({
              width: 328,
              height: "45px",
              padding: "14px 16px 12px 16px",
              background: theme.palette.secondary.light,
              borderRadius: 10,
              borderBottom: `1px solid ${theme.palette.secondary.main}`,
              color: theme.palette.text.primary,
              marginBottom: "14px",

              "& input": {
                fontSize: "1.4rem",
                lineHeight: "2.3rem",
                fontWeight: 400,
              },

              "& input::placeholder": {
                fontSize: "1.4rem",
                fontWeight: 400,
                color: theme.palette.text.primary,
                opacity: 1,
              },

              "& svg": {
                marginRight: "9px",
                height: "19px",
                width: "19px",
              },
            }),
            !!error && {
              background: theme => theme.palette.error.light,
              borderBottom: theme => `1px solid ${theme.palette.error.main}`,
              color: theme => theme.palette.error.main,
              marginBottom: 0,
              "& input::placeholder": {
                fontSize: "1.4rem",
                fontWeight: 400,
                color: theme => theme.palette.error.main,
                opacity: 1,
              },
              "& path": {
                fill: theme => theme.palette.error.main,
              },
            },
          ]
        }
        {...field}
      />
      {error && helperText && (
        <Box
          sx={theme => ({
            color: theme.palette.error.main,
            fontSize: "1.2rem",
            fontFamily: "figtree",
            lineHeight: "1.4rem",
          })}
        >
          {helperText}
        </Box>
      )}
    </Box>
  );
};

export const FormInput: React.FC<
  {
    control: Control<any>;
    name: string;
    rules?: Omit<
      RegisterOptions,
      "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    >;
  } & CustomInputProps
> = ({ control, name, rules, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ fieldState, field }) => (
        <CustomInput
          helperText={fieldState?.error?.message}
          error={!!fieldState?.error}
          field={field}
          {...props}
        />
      )}
    />
  );
};

export default CustomInput;
