import { Box } from "@mui/material";
import { Email } from "assets/IconsComponents/Email";
import { PasswordLock } from "assets/IconsComponents/PasswordLock";
import PseudoInput from "./PseudoInput";
import SplashCard from "./SplashCard";

const LoginCard: React.FC<{
  primary: string;
  secondary: string;
  textColor: string;
  spacing: (size: number) => number;
}> = ({ primary, secondary, textColor, spacing }) => {
  return (
    <SplashCard secondary={secondary} spacing={spacing}>
      <Box
        sx={{
          width: spacing(375),
          height: spacing(812),
          display: "flex",
          alignItems: "end",
        }}
      >
        <Box
          sx={{
            width: spacing(375),
            height: spacing(460),
            boxSizing: "border-box",
            backgroundColor: "#fff",
            borderRadius: "14px 14px 0 0",
            padding: `${spacing(39)}px ${spacing(23)}px`,
            display: "flex",
            flexDirection: "column",
            color: textColor,
          }}
        >
          <Box
            sx={{
              fontSize: spacing(24),
              marginBottom: spacing(0.75),
            }}
          >
            Welcome back!
          </Box>
          <Box sx={{ fontSize: spacing(16), mb: spacing(4.25) }}>
            We happy to see you again. To use your account you should log in
            first.
          </Box>
          <PseudoInput
            primary={primary}
            textColor={textColor}
            StartIcon={Email}
            text="Email"
            mb={3.75}
            spacing={spacing}
          />
          <PseudoInput
            primary={primary}
            textColor={textColor}
            StartIcon={PasswordLock}
            text="Password"
            mb={2.5}
            spacing={spacing}
          />
          <Box
            sx={{
              color: primary,
              fontSize: `${spacing(1.2)}rem`,
              textDecoration: "underline",
              alignSelf: "end",
              mb: spacing(3),
            }}
          >
            Forgot password?
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: spacing(2.625),
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: spacing(40),
                width: `${spacing(248)}px`,
                backgroundColor: secondary,
                borderRadius: spacing(32),

                color: theme => theme.palette.common.white,
                fontSize: `${spacing(1.6)}rem`,
                lineHeight: `${spacing(1.9)}rem`,
                fontWeight: 600,
              }}
            >
              Sign In
            </Box>
          </Box>
          <Box
            sx={{
              color: textColor,
              fontSize: `${spacing(1.2)}rem`,
              alignSelf: "center",
              mb: spacing(3),
            }}
          >
            Don't have account?
            <Box
              sx={{
                color: primary,
                textDecoration: "underline",
                display: "inline",
              }}
            >
              Sign in
            </Box>
          </Box>
        </Box>
      </Box>
    </SplashCard>
  );
};

export default LoginCard;
