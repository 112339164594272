export const accessTokenKey = "voyAdminPanel_accessToken";
export const refreshTokenKey = "voyAdminPanel_refreshToken";
export const emailRegex =
  /[a-z0-9][a-z0-9!#$%&'*+/=?^_`{|}~,.-]*[a-z0-9]@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

export const commonSymbolsRegex = /^[\w&.-]+$/;

export const minPasswordLength = { value: 8, message: "Min length 8" };
export const maxPasswordLength = { value: 15, message: "Max length 15" };
export const passwordValidationRules = {
  required: "Required",
  minLength: minPasswordLength,
  maxLength: maxPasswordLength,
  pattern: commonSymbolsRegex,
};

export const emailValidationRules = {
  required: "Required",
  pattern: {
    value: emailRegex,
    message: "invalid email",
  },
  minLength: { value: 6, message: "Min 6" },
};

export const defaultColors = {
  primary: "#FCAB38",
  secondary: "#00A6B3",
  mainText: "#231F20",
};
