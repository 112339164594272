import React, { useEffect } from "react";
import { Paper, Box, CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomerStore from "singletons/CustomerStore";
import { BoatImage } from "assets/IconsComponents/BoatImage";
import Loader from "components/Loader";

const BoatsList: React.FC<{ customerID: string }> = ({ customerID }) => {
  const {
    isCustomerBoatsLoading: isBoatsLoading,
    boats,
    hasMoreBoats,
    getCustomerBoats,
    getMoreCustomerBoats,
  } = CustomerStore;

  useEffect(() => {
    getCustomerBoats(customerID);
  }, [customerID, getCustomerBoats]);

  return isBoatsLoading ? (
    <Loader />
  ) : (
    <Paper
      id={"tableScrollWrapper"}
      sx={{
        width: "100%",
        height: "100%",
        overflowX: "auto",
        boxShadow: "none",
      }}
    >
      {boats.length ? (
        <InfiniteScroll
          dataLength={boats.length}
          next={() => {
            getMoreCustomerBoats(customerID);
          }}
          loader={
            <Box
              sx={{
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          }
          scrollThreshold={0.7}
          hasMore={hasMoreBoats}
          style={{
            overflow: "none",
            marginRight: 4,
          }}
          scrollableTarget={"tableScrollWrapper"}
        >
          {boats.map(({ name, photo }) => (
            <Box
              sx={{
                height: "80px",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.6rem",
                  fontWeight: 500,
                  textAlign: "center",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: 1.25,
                }}
              >
                <Box
                  sx={{
                    height: 60,
                    width: 60,
                    backgroundColor: ({ palette }) => palette.primary.main,
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& svg": {
                      height: 20,
                      width: 20,
                    },
                    "& img": {
                      height: "inherit",
                      width: "inherit",
                      borderRadius: "10px",
                    },
                  }}
                >
                  {photo ? (
                    <img src={photo.file_url} alt={"BoatIMG"} />
                  ) : (
                    <BoatImage />
                  )}
                </Box>

                <Box>{name}</Box>
              </Box>
            </Box>
          ))}
        </InfiniteScroll>
      ) : (
        <Box
          sx={{
            height: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.6rem",
            fontWeight: 300,
          }}
        >
          The user hasn't added boats yet
        </Box>
      )}
    </Paper>
  );
};

export default observer(BoatsList);
