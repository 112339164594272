import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import CustomerStore from "singletons/CustomerStore";
import BoatsList from "./BoatsList";
import TripsList from "./TripsList";
import CustomerProfile from "./CustomerProfile";

function CustomerDetails() {
  const { customerID } = useParams();

  useEffect(() => {
    return () => CustomerStore.clear();
  }, [customerID]);

  if (!customerID) {
    return <Navigate to={"/users"} />;
  }

  return (
    <Box sx={{ height: "calc(100vh - 112px)" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          marginBottom: "20px",
          height: "300px",
          gap: "30px",
        }}
      >
        <Box sx={{ width: "300px" }}>
          <CustomerProfile customerID={customerID} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ fontSize: "2rem", fontWeight: 500, mb: 2.5 }}>
            Boats list
          </Box>
          <BoatsList customerID={customerID} />
        </Box>
      </Box>
      <Box sx={{ fontSize: "2rem", fontWeight: 500, mb: 2.5 }}>Trips list</Box>
      <TripsList customerID={customerID} />
    </Box>
  );
}

export default observer(CustomerDetails);
