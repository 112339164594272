import { Box } from "@mui/material";
import { Logo } from "assets/IconsComponents/Logo";

const SplashBG = require("../../assets/icons/SplashBG.png");

const SplashCard: React.FC<
  React.PropsWithChildren & {
    secondary: string;
    spacing: (size: number) => number;
  }
> = ({ secondary, children = null, spacing }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${SplashBG})`,
        width: spacing(375),
        height: spacing(812),
        backgroundSize: "cover",
        position: "relative",
        zIndex: 0,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: `linear-gradient(180deg, ${secondary}A2 0%, ${secondary}D6 48.27%, ${secondary} 100%)`,
          opacity: 0.8,
          position: "absolute",
          zIndex: -1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          svg: {
            width: spacing(154),
            height: spacing(86),
          },
        }}
      >
        <Logo />
      </Box>
      {children}
    </Box>
  );
};

export default SplashCard;
