import { makeAutoObservable } from "mobx";
import request from "api";
import { successNotification } from "utils/notifications";

class MassNotificationStore {
  isPolicyLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  sendMassNotification = (payload: string) => {
    request({
      url: `/api/admin/notifications`,
      method: "post",
      data: { text: payload },
    }).then(() => {
      successNotification("Notification successfully created!");
    });
  };

  setIsPolicyLoading = (isLoading: boolean) => {
    this.isPolicyLoading = isLoading;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MassNotificationStore();
