import request, { publicAxios } from "api";
import { AxiosError, AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { accessTokenKey } from "utils/constants";
import { errorNotification, successNotification } from "utils/notifications";
import Login from "./Login";

class User {
  userInfo: {
    first_name: string;
    last_name: string;
    email: string;
    id: number;
  } | null = null;

  isUserInfoLoading = false;
  isUserRegistrationPending = false;
  isUserRegistrationConfirmPending = false;
  isUserChangePasswordPending = false;

  constructor() {
    makeAutoObservable(this);
  }

  getUserInfo = () => {
    const token = localStorage.getItem(accessTokenKey);
    if (token) {
      this.setUserInfoLoading(true);

      publicAxios
        .get(`/api/admin/token/${token}`)
        .then(
          (
            data: AxiosResponse<{
              first_name: "string";
              last_name: "string";
              email: "user@example.com";
              id: 0;
            }>,
          ) => {
            runInAction(() => {
              this.userInfo = data.data;
            });
          },
        )
        .catch(() => {
          errorNotification("An error occurred while retrieving user info");
          Login.logout();
        })
        .finally(() => {
          this.setUserInfoLoading(false);
        });
    }
  };

  changePassword = (
    payload: {
      current_password: string;
      new_password: string;
      new_password_confirm: string;
    },
    userID: number,
    successCallback?: () => void,
  ) => {
    this.setUserChangePasswordPending(true);

    request({
      url: `/api/admin/users/${userID}/change-password`,
      method: "post",
      data: payload,
    })
      .then(() => {
        successNotification("Password successfully changed!");
        successCallback && successCallback();
      })
      .catch(() => {
        errorNotification("An error occured while changing a password");
      })
      .finally(() => {
        this.setUserChangePasswordPending(false);
      });
  };

  inviteAdmin = (
    payload: {
      first_name: string;
      last_name: string;
      email: string;
    },
    successCallback?: () => void,
  ) => {
    this.setUserRegistrationPending(true);

    request({
      url: `/api/admin/register`,
      method: "post",
      data: payload,
    })
      .then(() => {
        successNotification("User successfully invited!");
        successCallback && successCallback();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 409) {
          errorNotification("This user already invited");
        } else {
          errorNotification("An error occured while inviting a user");
        }
      })
      .finally(() => {
        this.setUserRegistrationPending(false);
      });
  };

  confirmUserRegister = (
    payload: { token: string; password: string; password_confirm: string },
    successCallback?: () => void,
  ) => {
    this.setUserRegistrationConfirmPending(true);

    publicAxios
      .post(`/api/admin/register/complete`, payload)
      .then(() => {
        successNotification("Registration successfull!");
        successCallback && successCallback();
      })
      .catch(() => {
        errorNotification("This token expired");
      })
      .finally(() => {
        this.setUserRegistrationConfirmPending(false);
      });
  };

  setUserInfoLoading = (isLoading: boolean) => {
    this.isUserInfoLoading = isLoading;
  };

  setUserRegistrationPending = (isLoading: boolean) => {
    this.isUserRegistrationPending = isLoading;
  };

  setUserRegistrationConfirmPending = (isLoading: boolean) => {
    this.isUserRegistrationConfirmPending = isLoading;
  };
  setUserChangePasswordPending = (isLoading: boolean) => {
    this.isUserChangePasswordPending = isLoading;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new User();
