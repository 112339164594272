import { useCallback, useState } from "react";
const throttle = require("lodash.throttle");

export const useThrottledState = <T>(
  initialState: T,
): [state: T, setState: (newState: T) => void] => {
  const [state, setState] = useState(initialState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetState = useCallback(throttle(setState, 100), [setState]);

  return [state, throttledSetState];
};
